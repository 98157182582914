<template>
  <div>
    <el-dialog
      title="报名信息"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      top="0"
      width="1175px"
      @close="close"
    >
      <div class="dialog_content">
        <img class="close" @click="dialogVisible = !dialogVisible" src="@/assets/image/icon/15.png" />
        <CustomForm
          class="form"
          :isTeam="true"
          :isReset="isReset"
          :formList="formList"
          :isSubmit="isSubmit"
          @resetForm="resetForm"
          @submitForm="submitForm"
        ></CustomForm>
        <div class="btn flex">
          <div class="box" @click="dialogVisible = !dialogVisible">取消</div>
          <div class="box" @click="resetClick">重置</div>
          <div class="box" @click="saveClick">保存</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CustomForm from '@/components/form/custom.vue'
export default {
  components: { CustomForm },
  props: {
    isPlayer: {
      type: Boolean,
      default() {
        return false
      }
    },
    formList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      isReset: false,
      isSubmit: false,
      dialogVisible: false
    }
  },

  mounted() {
    localStorage.setItem('entryType', 2)
  },

  methods: {
    close() {
      this.$emit('close', false)
    },
    // 提交成功的回调
    submitForm(form) {
      this.isSubmit = false
      if (JSON.stringify(form) == '{}') return
      this.dialogVisible = !this.dialogVisible

      for (let i = 0; i < this.formList.length; i++) {
        Object.keys(form).forEach((key) => {
          if (this.formList[i].model == key) {
            this.formList[i].value = form[key]
          }
        })
      }
      this.$emit('form', form)
      this.$emit('next', this.formList)
    },
    // 重置成功的回调
    resetForm() {
      this.isReset = false
    },
    // 重置按钮
    resetClick() {
      this.isReset = true
    },
    // 保存按钮
    saveClick() {
      this.isSubmit = true
    }
  },
  watch: {
    isPlayer: {
      handler(val) {
        this.dialogVisible = val
      },
      deep: true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__title {
  height: 50px;
  line-height: 50px;
  color: @color_six;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: @font_size_16;
  background-color: #eeeeee;
}
/deep/ .dialog_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/deep/ .form {
  border: none;
}

.close {
  top: 18px;
  right: 22px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
}
.dialog_content {
  width: 100%;
  height: 620px;
  overflow: hidden;
  border-radius: 5px;
  position: relative;
  /deep/ .demo-ruleForm {
    width: 80%;
    height: 100%;
    max-height: 500px;
    overflow-y: auto;
    margin: 0 auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    /* 滚动条背景颜色 */
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }
    /* 滚动条当前背景颜色 */
    &::-webkit-scrollbar-thumb {
      background-color: #e1e1e1;
    }
  }
  .btn {
    margin: 40px 0 20px 0px;
    .box {
      width: 130px;
      height: 42px;
      cursor: pointer;
      line-height: 42px;
      text-align: center;
      border-radius: 3px;
      margin-right: 30px;
      &:nth-child(1) {
        color: @color_sixteen;
        border: 1px solid #cccccc;
        background-color: @color_bg_two;
      }
      &:nth-child(2) {
        color: @color_one;
        border: 1px solid @color_one;
      }
      &:nth-child(3) {
        color: #fff;
        background-color: @color_one;
      }
    }
  }
}
</style>
