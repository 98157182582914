<template>
  <div class="team">
    <div class="team_main">
      <div class="vertical_title flex">团队信息</div>
      <div class="mess flex_direction_column" v-if="info">
        <div class="desc">报名人员 限{{ info.group.min_people }}-{{ info.group.max_people }}人</div>
        <div class="item flex_direction_column" v-for="i in info.user" :key="i.id">
          <div class="item_top flex">
            <span>{{ i.name }}</span>
            <span></span>
            <span>{{ i.mobile }}</span>
            <span>详情</span>
          </div>
          <div class="item_bottom flex_direction_column">
            <div class="per">个人信息</div>
            <div class="list flex_direction_column" v-for="ii in i.sign_form" :key="ii.model">
              <div class="list_item flex">
                <span>{{ ii.model }}</span>
                <span v-if="ii.type != 6 && ii.type != 8 && ii.type != 9">{{ ii.value }}</span>
                <div class="type flex_wrap" v-if="ii.type == 6">
                  <span v-for="(iii, vvv) in ii.value" :key="vvv">{{ iii }}</span>
                </div>
                <div class="type flex_wrap" v-if="ii.type == 8 || ii.type == 9">
                  <img v-for="(iii, vvv) in ii.value" :key="vvv" :src="iii" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="price flex_direction_column">
        <span>报名费用</span>
        <!-- <span class="flex">¥{{ price ?? info.order.total_price }}</span> -->
        <span class="flex" v-if="price ?? info.order.total_price != '0.0'">¥{{ price ?? info.order.total_price }}</span>
        <span class="flex" v-else>免费</span>
      </div>
    </div>

    <div class="qrcode flex" v-if="info && info.order.state == 2 && info.active.type != 2" @click="qrcode(info.order)">
      <img :src="getChecked(info.order).url" />
      <span class="flex" :style="getChecked(info.order).style">{{ getChecked(info.order).text }}</span>
    </div>

    <!-- 签到码弹窗 -->
    <SigInPopup :isSignIn="isSignIn" :info="info.codeData" @close="close"></SigInPopup>
  </div>
</template>

<script>
import SigInPopup from '@/components/popup/signin.vue'
import { matchCashier } from '@/service/api/index'
export default {
  components: { SigInPopup },
  props: {
    orderId: {
      type: String,
      default() {
        return '0'
      }
    }
  },
  data() {
    return {
      isSignIn: false,
      info: '',
      price: sessionStorage.getItem('price') ?? 0
    }
  },

  mounted() {
    this.matchCashier()
  },

  methods: {
    getChecked(item) {
      if (item.checked == 1) {
        return {
          text: '未核销',
          style: 'color:#E6425E',
          url: require('@/assets/image/img/36.png')
        }
      } else if (item.checked == 2) {
        return {
          text: '已核销',
          style: 'color:#6FBA2C',
          url: require('@/assets/image/img/37.png')
        }
      } else if (item.checked == 3) {
        return {
          text: '已过期',
          style: 'color:#777777',
          url: require('@/assets/image/img/37.png')
        }
      }
    },
    // 收银台
    async matchCashier() {
      const res = await matchCashier({ id: this.orderId })
      if (res.code == 1) {
        this.info = res.msg
        res.msg.order.time = res.msg.time
        this.price = res.msg.order.total_price
        this.$emit('change', res.msg.order)
      }
    },
    close() {
      this.isSignIn = false
    },
    // 打开签到码弹窗
    qrcode(item) {
      if (this.getChecked(item).text != '已过期') return (this.isSignIn = true)
      this.$message('已过期')
    }
  }
}
</script>

<style lang="less" scoped>
.qrcode {
  top: 30px;
  right: 40px;
  cursor: pointer;
  position: absolute;
  img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
  span {
    height: 30px;
    line-height: 30px;
    font-weight: normal;
    color: @color_one;
    font-size: @font_size_18;
    &::after {
      content: '\ff08\70b9\51fb\67e5\770b\5927\56fe\ff09';
      height: 20px;
      font-size: 14px;
      margin-left: 5px;
      line-height: 20px;
      color: @color_eight;
    }
  }
}
.team {
  position: relative;
  margin-top: 20px;
  border-radius: 3px;
  margin-bottom: 23px;
  background-color: #fff;
  .team_main {
    padding: 31px 45px 29px 45px;
    .mess {
      padding: 21px 0 36px 0;
      border-bottom: 1px dashed #eee;
      .desc {
        height: 22px;
        line-height: 22px;
        font-size: @font_size_16;
      }
      .item {
        width: 450px;
        cursor: pointer;
        margin-top: 20px;
        overflow: hidden;
        &:hover {
          .item_bottom {
            opacity: 1;
            height: auto;
            margin: 18px 33px 0 33px;
          }
          .item_top {
            border: none;
            height: 30px;
            padding: 10px 0 15px 0;
            background-color: transparent;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url('~@/assets/image/img/16.png');
          }
        }
        .item_top {
          width: 450px;
          padding: 15px 0;
          border: 1px solid #eeeeee;
          background-color: @color_bg_two;
          span {
            font-size: @font_size_14;
            &:nth-child(1) {
              margin-left: 13px;
            }
            &:nth-child(2) {
              margin-left: 60px;
            }
            &:nth-child(3) {
              margin-left: 25px;
            }
            &:nth-child(4) {
              flex: 1;
              margin-right: 40px;
              text-align: right;
              color: @color_seventeen;
            }
          }
        }
        .item_bottom {
          height: 0;
          opacity: 0;
          transition: all 0.3s;
          .per {
            height: 20px;
            line-height: 20px;
            color: @color_eighteen;
            font-size: @font_size_14;
          }
          .list {
            margin-top: 13px;
            margin-left: 61px;
            .list_item {
              // height: 30px;
              line-height: 30px;
              font-size: @font_size_14;
              .type {
                flex: 1;
                img {
                  width: 40px;
                  height: 40px;
                  object-fit: cover;
                  border-radius: 5px;
                  margin-right: 10px;
                }
              }
              span {
                height: 30px;
                line-height: 30px;
                &:nth-child(1) {
                  width: 106px;
                  color: @color_seven;
                }
                &:nth-child(2) {
                  color: #000;
                  // margin-left: 30px;
                }
              }
            }
          }
        }
      }
    }
    .price {
      margin-top: 28px;
      span {
        &:nth-child(1) {
          height: 28px;
          line-height: 28px;
          font-size: @font_size_20;
        }
        &:nth-child(2) {
          height: 42px;
          margin-top: 12px;
          line-height: 42px;
          color: @color_one;
          font-size: @font_size_26;
          &::before {
            content: '\5171\8ba1';
            width: 45px;
            height: 25px;
            color: #000;
            line-height: 25px;
            margin-top: 5px;
            font-size: @font_size_18;
          }
        }
      }
    }
  }
}
</style>
