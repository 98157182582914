<template>
  <div class="content" v-if="info">
    <!-- 基本信息 -->
    <SingleInfo :status="1" :info="info"></SingleInfo>
    <!-- 单人赛 -->
    <div v-if="info.group.type == 1">
      <!-- 报名表单 -->
      <SingleForm v-if="!params" title="报名信息" :form="formList ?? info.group" @next="next"></SingleForm>
      <!-- 报名信息 -->
      <EntryInfo v-if="params" :id="orderId" :info="info" title="参赛选手信息" @change="orderChange"></EntryInfo>
    </div>
    <!-- 团队赛 -->
    <div v-if="info.group.type == 2">
      <TeamForm v-if="!params" title="团队信息" :info="info.group" @next="next"></TeamForm>
      <TeamInfo v-if="params" :orderId="orderId" @change="orderChange"></TeamInfo>
    </div>
    <!-- 倒计时 -->
    <CountDown v-if="params && order.state == 1" :time="time" @end="countEnd"></CountDown>
    <!-- 支付方式 -->
    <Payment v-if="params && order.state == 1" :orderId="orderId" @back="back" @success="successPay"></Payment>
    <!-- 订单信息 -->
    <OrderInfo v-if="order && order.state == 2" :order="order"></OrderInfo>
    <!-- 支付状态弹窗 -->
    <payPopup @close="close"></payPopup>
  </div>
</template>

<script>
import { matchCashier } from '@/service/api/index'
import { matchOrder } from '@/service/api/match'
import { orderCancel } from '@/service/api/user'
import payPopup from '@/components/popup/pay.vue'
import Payment from '@/components/entry/payment.vue'
import TeamForm from '@/components/entry/teamform.vue'
import TeamInfo from '@/components/entry/teaminfo.vue'
import EntryInfo from '@/components/entry/entryinfo.vue'
import OrderInfo from '@/components/entry/orderinfo.vue'
import CountDown from '@/components/entry/countdown.vue'
import SingleInfo from '@/components/entry/singleinfo.vue'
import SingleForm from '@/components/entry/singleform.vue'
export default {
  components: {
    SingleInfo,
    SingleForm,
    EntryInfo,
    OrderInfo,
    Payment,
    TeamForm,
    TeamInfo,
    payPopup,
    CountDown
  },
  data() {
    return {
      info: '',
      params: null,
      time: '',
      // 0 单人 1 团体
      way: this.$route.query.way,
      order: '',
      orderId: this.$route.query.orderId ?? '',
      formList: JSON.parse(sessionStorage.getItem('formList'))
    }
  },

  mounted() {
    if (!this.$route.query.orderId) {
      // 赛事提交表单页面
      this.matchOrder()
    } else {
      // 收银台
      this.matchCashier()
    }
  },

  methods: {
    // 收银台
    async matchCashier() {
      const res = await matchCashier({ id: this.$route.query.orderId })
      this.info = res.msg
      this.params = true
      // this.time = this.info.time
    },
    // 赛事提交表单页面
    async matchOrder() {
      const res = await matchOrder({ id: this.$route.query.id })
      this.info = res.msg
    },
    // 支付成功
    successPay(params) {
      this.order = params
    },
    next(obj) {
      this.params = obj.formList
      this.orderId = obj.orderId
      document.documentElement.scrollTop = 0
    },
    back() {
      this.params = null
    },
    // 订单信息
    orderChange(params) {
      console.log('params', params, params.verify_time)
      this.time = params.time
      this.order = params
    },
    close() {
      this.$store.state.payPopup = false
    },
    async countEnd() {
      const res = await orderCancel({ id: this.orderId })
      if (res.code == 1) return this.$router.go(-1)
      console.log(res)
    }
  }
}
</script>

<style lang="less" scoped></style>
