<template>
  <div class="team">
    <div class="team_main">
      <div class="vertical_title flex">{{ title }}</div>
      <div class="form flex_direction_column">
        <div class="form_title">报名人员 限{{ info.min_people }}-{{ info.max_people }}人</div>
        <div class="item flex" v-for="(item, index) in list" :key="index">
          <div class="desc" @click="itemClick(item, index)" v-if="item.length == 0">+添加队员</div>
          <div class="desc" @click="itemClick(item, index)" v-else>
            {{ strPlayer(item) }}
          </div>
          <span @click="itemClick(item, index)">修改</span>
          <span @click="deleteClick(index)">删除</span>
        </div>
        <div class="add" @click="addClick" v-if="list.length != info.max_people">新增参赛队员</div>
      </div>
      <div class="price flex_direction_column">
        <span>报名费用</span>
        <span class="flex">¥{{ price ?? info.price }}</span>
        <div class="xy flex">
          <div class="check" @click="check = !check">
            <img v-if="check" src="@/assets/image/icon/13.png" />
          </div>
          <p class="flex" @click="isClause = !isClause">《参赛条款》</p>
        </div>
        <div class="btn flex">
          <div class="box" @click="cancelClick">取消</div>
          <div class="box" @click="nextClick">下一步</div>
        </div>
      </div>
    </div>
    <PlayerPopup
      :formList="formList"
      :isPlayer="isPlayer"
      @form="formChange"
      @close="closePlayer"
      @next="nextPlayer"
    ></PlayerPopup>
    <ClausePopup
      title="参赛条款"
      :html="$store.state.config.treaty"
      :isClause="isClause"
      @close="closeClause"
    ></ClausePopup>
  </div>
</template>

<script>
import { addMatchOrder } from '@/service/api/match'
import PlayerPopup from '@/components/popup/player.vue'
import ClausePopup from '@/components/popup/clause.vue'
export default {
  props: ['title', 'info'],
  components: { PlayerPopup, ClausePopup },
  data() {
    return {
      index: 0,
      params: {},
      check: false,
      isPlayer: false,
      isClause: false,
      formList: [],
      price: sessionStorage.getItem('price'),
      list: JSON.parse(sessionStorage.getItem('list')) ?? [],
      form: JSON.parse(sessionStorage.getItem('form')) ?? []
    }
  },

  mounted() {
    if (this.list.length == 0) {
      for (let index = 0; index < this.info.min_people; index++) {
        this.list.push([])
        this.form.push([])
      }
      this.sessionStorage()
    }
  },

  methods: {
    // 赛事报名提交
    async addMatchOrder() {
      const res = await addMatchOrder({
        id: this.$route.query.id,
        formList: this.list
      })
      if (res.code == 1) {
        let obj = {
          formList: this.list,
          orderId: res.msg.id
        }
        this.$emit('next', obj)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 本地存储
    sessionStorage() {
      sessionStorage.setItem('list', JSON.stringify(this.list))
      sessionStorage.setItem('form', JSON.stringify(this.form))
    },
    // 关闭弹窗回调
    closePlayer() {
      this.isPlayer = false
    },
    closeClause() {
      this.isClause = false
    },
    // 接收报名信息
    nextPlayer(params) {
      this.params = params
      this.list[this.index] = JSON.parse(JSON.stringify(params))
      console.log(this.list)
      this.sessionStorage()
    },
    // 接收表单数据
    formChange(form) {
      this.form[this.index] = JSON.parse(JSON.stringify(form))
      this.sessionStorage()
    },
    // 回显文字
    strPlayer(item) {
      let str = ''
      item.forEach((i) => {
        Object.keys(i).forEach((key) => {
          if (i[key] == '姓名' || i[key] == '手机号') {
            str += i.value
          }
        })
      })
      return str
    },
    // 循环项
    itemClick(item, index) {
      this.index = index
      this.isPlayer = true
      if (item.length > 0) {
        this.$store.state.teamForm = this.form[index]
      } else {
        this.$store.state.teamForm = {}
      }
      this.formList = this.info.sign_form
    },
    // 新增循环项
    addClick() {
      if (this.list.length != this.info.max_people) {
        this.list.push([])
        this.form.push([])
        this.sessionStorage()
      } else {
        this.$message.warning(`最多添加${this.info.max_people}个人`)
      }
    },
    // 删除循环项
    deleteClick(index) {
      if (this.list.length == this.info.min_people) return this.$message.warning('报名人数，最少一人')
      this.list.splice(index, 1)
      this.form.splice(index, 1)
      this.sessionStorage()
    },
    // 取消按钮
    cancelClick() {},
    // 下一步
    nextClick() {
      if (this.check) {
        sessionStorage.setItem('price', this.info.price ?? this.price)
        this.addMatchOrder()
        // this.$emit('next', this.list)
      } else {
        this.$message.warning('请勾选协议')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.team {
  margin-top: 20px;
  border-radius: 3px;
  margin-bottom: 45px;
  background-color: #fff;
  .team_main {
    padding: 36px 45px 52px 45px;
    .form {
      padding: 20px 16px 31px 16px;
      border-bottom: 1px solid #eee;
      .form_title {
        height: 22px;
        line-height: 22px;
        font-size: @font_size_16;
      }
      .item {
        margin-top: 22px;
        .desc {
          width: 338px;
          cursor: pointer;

          padding: 10px 13px;
          border-radius: 5px;
          color: @color_sixteen;
          border: 1px solid #eeeeee;
        }
        span {
          width: 50px;
          height: 20px;
          cursor: pointer;
          line-height: 20px;
          margin-left: 25px;
          font-size: @font_size_16;
          &:nth-child(2) {
            color: @color_seventeen;
          }
          &:nth-child(3) {
            color: @color_one;
          }
        }
      }
      .add {
        width: 144px;
        height: 42px;
        color: #fff;
        margin-top: 23px;
        cursor: pointer;
        line-height: 42px;
        text-align: center;
        border-radius: 3px;
        background: @color_five;
      }
    }
    .price {
      margin-top: 32px;
      padding-left: 16px;
      span {
        &:nth-child(1) {
          height: 28px;
          line-height: 28px;
          font-size: @font_size_20;
        }
        &:nth-child(2) {
          margin-top: 12px;
          color: @color_one;
          font-size: @font_size_26;
          &::before {
            content: '\5171\8ba1';
            width: 45px;
            height: 25px;
            color: #000;
            line-height: 25px;
            font-size: @font_size_18;
          }
        }
      }
      .xy {
        margin-top: 36px;
        .check {
          width: 24px;
          height: 24px;
          cursor: pointer;
          border-radius: 3px;
          border: 1px solid #eee;
          margin-right: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        p {
          cursor: pointer;
          color: @color_fifteen;
          font-size: @font_size_18;
          &::before {
            content: '\6211\5df2\9605\8bfb\5e76\540c\610f';
            margin-right: 5px;
            color: @color_six;
          }
        }
      }
    }
    .btn {
      margin-top: 36px;
      .box {
        width: 130px;
        height: 42px;
        cursor: pointer;
        line-height: 42px;
        text-align: center;
        border-radius: 3px;
        &:nth-child(1) {
          margin-right: 30px;
          color: @color_sixteen;
          background-color: #f9f9f9;
          border: 1px solid #cccccc;
        }
        &:nth-child(2) {
          color: #fff;
          background-color: @color_one;
        }
      }
    }
  }
}
</style>
